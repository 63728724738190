
import React, {useEffect, useState} from "react";

import "../../assets/vendor/css/all.min.css";
import "../../assets/vendor/css/OverlayScrollbars.min.css";
import "../../assets/vendor/css/bootstrap.min.css";
import "../../assets/css/style.css";


export default function Guest({ children }) {
    return (
        <div id="App">
            {children}
        </div>
    );
}
